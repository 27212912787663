figure {
    /* Style the Image Used to Trigger the Modal */
    .lazy-link {
        cursor: zoom-in;
        transition: 0.3s;
    }

    .lazy-link:hover {
        opacity: 0.7;
    }

    /* The Modal (background) */
    div.modal {
        display: none; /* Hidden by default */
        position: fixed; /* Stay in place */
        z-index: 1; /* Sit on top */
        padding-top: 100px; /* Location of the box */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background-color: rgb(0, 0, 0); /* Fallback color */
        background-color: rgba(0, 0, 0, 0.9); /* Black w/ opacity */
    }

    /* Modal Content (Image) */
    img.modal-content {
        cursor: zoom-out;
        margin: auto;
        display: block;
        width: 95%;
    }

    /* Caption of Modal Image (Image Text) - Same Width as the Image */
    div figcaption {
        margin: auto;
        display: block;
        width: 80%;
        max-width: 700px;
        text-align: center;
        color: #ccc;
        padding: 10px 0;
        height: 150px;
    }

    /* Add Animation - Zoom in the Modal */
    img.modal-content,
    div figcaption {
        -webkit-animation-name: zoom-in;
        -webkit-animation-duration: 0.6s;
        animation-name: zoom-in;
        animation-duration: 0.6s;
    }

    @-webkit-keyframes zoom-in {
        from {
            -webkit-transform: scale(0);
        }
        to {
            -webkit-transform: scale(1);
        }
    }

    @keyframes zoom-in {
        from {
            transform: scale(0);
        }
        to {
            transform: scale(1);
        }
    }

    /* The Close Button */
    span.close {
        position: absolute;
        top: 15px;
        right: 35px;
        color: #f1f1f1;
        font-size: 40px;
        font-weight: bold;
        transition: 0.3s;
    }

    span.close:hover,
    span.close:focus {
        color: #bbb;
        text-decoration: none;
        cursor: pointer;
    }

    /* 100% Image Width on Smaller Screens */
    @media only screen and (max-width: 700px) {
        .modal-content {
            width: 100%;
        }
    }
}
